import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/video-bg.jpg");
var bgimg2 = require("./../../images/background/cross-line2.png");

class ConceptDesignAbout5 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">Excellence in Concept Design</h3>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <p>
                      At Studio Sarva Architects, we take pride in delivering
                      visionary concept designs that form the blueprint for
                      exceptional spaces. Whether you're developing a
                      commercial, residential, or hospitality project, our
                      concept designs are tailored to reflect your unique vision
                      and the goals of your business. We specialize in
                      transforming ideas into detailed, creative, and functional
                      concepts, providing the foundation for construction
                      projects that stand out for their innovation, aesthetic
                      appeal, and practicality. Our process is centered around
                      collaboration, ensuring that your input is integrated at
                      every step, resulting in a design that is both inspiring
                      and achievable.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <p className="service-details-points">
                        Tailored design concepts that align with your project’s goals and vision.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Innovative designs with a focus on functionality, sustainability, and trends.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Detailed feasibility studies to ensure practical execution.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Collaborative design process, keeping you involved throughout.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Concepts that reflect brand identity and enhance user experience.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ConceptDesignAbout5;
