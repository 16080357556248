import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/video-bg.jpg");

class HospitalityAbout3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <h3 className="m-t0 m-b20 sx-tilte">
                      Hospitality Construction
                    </h3>
                    <p>
                      At Studio Sarva Architecture, we bring unparalleled
                      expertise to hospitality construction, creating spaces
                      that offer both functionality and luxury. From hotels and
                      resorts to restaurants and entertainment venues, we handle
                      every aspect of the construction process with precision
                      and creativity, ensuring that your establishment stands
                      out and provides an exceptional guest experience. Our
                      hospitality construction services are designed to meet the
                      specific needs of hospitality businesses, combining
                      aesthetics, comfort, and operational efficiency.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <p className="service-details-points">
                        End-to-end project management for hospitality builds.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Custom designs that reflect your brand identity and vision.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Use of premium materials and innovative construction techniques.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Timely delivery with a focus on guest satisfaction and operational needs.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Clear communication and collaboration throughout the project.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HospitalityAbout3;
