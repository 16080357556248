import React from 'react';
import { NavLink } from 'react-router-dom';

var bgimg1 = require('./../../images/background/bg1.png');

class About2 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b80 bg-gray">
                        <div className="container">
                            <div className="section-content">
                                <div className="row">
                                    <div className="col-xl-5 col-lg-5 col-md-12 ">
                                        <div className="home-2-about bg-bottom-left bg-no-repeat bg-cover" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                        </div>
                                    </div>
                                    <div className="col-xl-7 col-lg-7 col-md-12">
                                        <div className="about-home-2">
                                            <h3 className="m-t0 sx-tilte">Our Mission</h3>
                                            <p>At Sarva Studio, our mission is to transform ideas into reality. We believe that every space—whether residential, commercial, or public—deserves a design that inspires and elevates. Our commitment is to provide our clients with seamless, end-to-end solutions, ensuring that every detail is thoughtfully considered and executed to perfection.</p>
                                            <div className="text-left">
                                            {/* <NavLink to={"/about-2"} className="site-button-link">Read More</NavLink> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    }
};

export default About2;