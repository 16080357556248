import React, { useState } from "react";
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import GoogleMapReact from "google-map-react";

const bnrimg = require("./../../images/banner/contact-banner.png");

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const ContactUs2 = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    service: "",
    phonenumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.username.trim()) errors.username = "Name is required";
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
    if (!formData.service.trim()) errors.service = "City is required";
    if (!formData.phonenumber.trim()) {
      errors.phonenumber = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phonenumber)) {
      errors.phonenumber = "Phone number is invalid";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formErrors = validateForm();

    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await fetch("https://studiosarva.com/forms/contact.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        const data = await response.json();

        if (response.ok) {
          if (data.status === "success") {
            window.location.href = "https://studiosarva.com/forms/thankyou.html";
          } else {
            console.error("Error:", data.message);
            setModalMessage(data.message || "An error occurred. Please try again.");
          }
        } else {
          console.error("Error:", data.message);
          setModalMessage(data.message || "An error occurred. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setModalMessage("An error occurred. Please try again later.");
      }
    } else {
      setErrors(formErrors);
      setModalMessage("Please correct the errors in the form.");
    }

    setIsLoading(false);
    setShowModal(true);
  };

  const defaultProps = {
    center: {
      lat: 34.07328,
      lng: -118.25141,
    },
    zoom: 12,
  };

  return (
    <>
      {/* <Header4 /> */}
      <div className="page-content">
        {/* <Banner
          title="Contact Us Form"
          pagename="Contact us"
          description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized."
          bgimage={bnrimg}
        /> */}
        <div className="section-full p-tb80 inner-page-padding">
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <form
                    className="contact-form cons-contact-form bg-gray p-a30"
                    onSubmit={handleSubmit}
                  >
                    <div className="contact-one">
                      <div className="section-head">
                        <div className="sx-separator-outer separator-left">
                          <div
                            className="sx-separator bg-white bg-moving bg-repeat-x"
                            style={{
                              backgroundImage: "url(images/background/cross-line2.png)",
                            }}
                          >
                            <h3 className="sep-line-one">Let's Bring Your Vision to Life</h3>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          name="name"
                          type="text"
                          value={formData.name}
                          onChange={handleInputChange}
                          className="form-control"
                          placeholder="Name"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="email"
                          type="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          className="form-control"
                          placeholder="Email"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="phonenumber"
                          type="tel"
                          value={formData.phonenumber}
                          onChange={handleInputChange}
                          className="form-control"
                          placeholder="Phone Number"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="message"
                          rows={4}
                          value={formData.message}
                          onChange={handleInputChange}
                          className="form-control"
                          placeholder="Message"
                          required
                        />
                      </div>
                      <div className="text-right">
                        <button type="submit" className="site-button btn-half">
                          <span>{isLoading ? "Submitting..." : "Submit"}</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default ContactUs2;
