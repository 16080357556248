import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom"; // Use useParams hook
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import Projects6 from "./../Elements/Projects6";
import ReactPlayer from "react-player";
import projectData from "../../assets/Jsondata/project.json";

var bnrimg = require("./../../images/banner/2.jpg");

const ProjectDetail1 = () => {
  const slug = useParams();
  console.log("slug", slug.projectslug);
  const [project, setProject] = useState(null);

  useEffect(() => {
    // Find the project data based on the slug
    const foundProject = projectData.projects.find(
      (p) => p.slug === slug.projectslug
    );
    if (foundProject) {
      setProject(foundProject);
    }
  }, [slug]);

  // Handle case where project data is not found
  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header4 />
      <div className="page-content">
        <Banner
          title={project.title}
          pagename="Project Detail"
          description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized."
          bgimage={bnrimg}
        />
        {/* SECTION CONTENT START */}
        <div className="section-full p-tb80 inner-page-padding stick_in_parent">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7  sticky_column">
                <div className="project-detail-containt">
                  <div className="bg-white text-black">
                    <h3>
                      A multitask profession which creates any land in beautiful
                      creation{" "}
                    </h3>
                    <p>
                      {" "}
                      Designers think everything done by someone else is awful,
                      and that they could do it better themselves, which
                      explains why I designed my own living room carpet, I
                      suppose. the architect represents neither a Dionysian nor
                      an Apollinian condition: here it is the mighty act of
                      will, the will which moves mountains, the intoxication of
                      the strong will, which demands artistic expression. The
                      most powerful men have always inspired the architects; the
                      architect has always been influenced by power.
                    </p>
                    <div className="product-block">
                      <ul>
                        <li>
                          <h4 className="m-b10">Date</h4>
                          <p>October 10, 2022</p>
                        </li>
                        <li>
                          <h4 className="m-b10">Client</h4>
                          <p>Studio Massimo, Italy</p>
                        </li>
                        <li>
                          <h4 className="m-b10">Project type</h4>
                          <p>Contruction, Brading</p>
                        </li>
                        <li>
                          <h4 className="m-b10">Location</h4>
                          <p>Mountain View CA 94043</p>
                        </li>
                        <li>
                          <h4 className="m-b10">Year</h4>
                          <p>2022</p>
                        </li>
                      </ul>
                    </div>
                    <div className="m-b0">
                      <div className="sx-divider divider-1px  bg-black">
                        <i className="icon-dot c-square" />
                      </div>
                    </div>
                    <ul className="social-icons social-square social-darkest m-b0">
                      <li>
                        <a
                          href="https://www.facebook.com"
                          target="_blank"
                          className="fa fa-facebook"
                        />
                      </li>
                      <li>
                        <a
                          href="https://twitter.com"
                          target="_blank"
                          className="fa fa-twitter"
                        />
                      </li>
                      <li>
                        <a
                          href="https://in.linkedin.com"
                          target="_blank"
                          className="fa fa-linkedin"
                        />
                      </li>
                      <li>
                        <a
                          href="https://rss.com"
                          target="_blank"
                          className="fa fa-rss"
                        />
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com"
                          target="_blank"
                          className="fa fa-youtube"
                        />
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com"
                          target="_blank"
                          className="fa fa-instagram"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 ">
                <div className="project-detail-outer">
                  <div className="project-detail-pic m-b30">
                    <div className="sx-media">
                      <img
                        src={require("./../../images/projects/portrait/pic7.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="project-detail-pic m-b30">
                    <div className="sx-media">
                      <img
                        src={require("./../../images/projects/portrait/pic4.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="project-detail-pic m-b30">
                    <div className="sx-media">
                      <img
                        src={require("./../../images/projects/portrait/pic5.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="sx-box">
                    <div className="sx-thum-bx sx-img-overlay1 sx-img-effect yt-thum-box">
                      <img
                        src="https://img.youtube.com/vi/Oy2QIiSQT2U/0.jpg"
                        alt=""
                      />
                      <NavLink
                        to={"#"}
                        className="play-now"
                        data-toggle="modal"
                        data-target="#myModal5"
                      >
                        <i className="icon fa fa-play" />
                        <span className="ripple" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-detail-containt-2 m-t50">
              <h3>
                Creating a sustainable future through building preservation,
                green architecture, and smart design
              </h3>
              <p className="m-b0">
                {" "}
                Designers think everything done by someone else is awful, and
                that they could do it better themselves, which explains why I
                designed my own living room carpet, I suppose. the architect
                represents neither a Dionysian nor an Apollinian condition: here
                it is the mighty act of will, the will which moves mountains,
                the intoxication of the strong will, which demands artistic
                expression. The most powerful men have always inspired the
                architects; the architect has always been influenced by power.
              </p>
            </div>
          </div>
        </div>
        {/* SECTION CONTENT END  */}
        <Projects6 />
      </div>
      <div className="modal fade" id="myModal5" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <ReactPlayer url="https://www.youtube.com/watch?v=Oy2QIiSQT2U" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProjectDetail1;
