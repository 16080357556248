import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/interior3.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class InteriorDesignAbout5 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">
                    Excellence in Interior Design
                  </h3>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <p>
                      At Studio Sarva Architects, we are committed to
                      transforming interiors into captivating, functional spaces
                      that reflect your vision and lifestyle. Our team of
                      experienced designers works closely with clients to
                      understand their preferences and needs, delivering
                      customized interior design solutions for homes, offices,
                      and commercial spaces. From space planning to decor
                      selection, we create environments that are not only
                      beautiful but also comfortable and sustainable.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <p className="service-details-points">
                        Customized interior design for homes, offices, and commercial spaces.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Expert space planning for optimal layout and functionality.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Selection of high-quality furnishings, finishes, and decor.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Sustainable interior solutions that blend style with environmental responsibility.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Full project management from concept to completion.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default InteriorDesignAbout5;
