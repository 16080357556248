import React from "react";
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import TrunkeySlider from "../Elements/TrunkeySlider";
import TrunkeyAbout3 from "../Elements/TrunkeyAbout";
import TrunkeyAbout5 from "../Elements/TrunkeyAbout5";
import TrunkeyService7 from "../Elements/TrunkeyService7";
import TrunkeyStatistics from "../Elements/TrunkeyStatistics";
import TrunkeyPlans from "../Elements/TrunkeyPlans";
import Banner from "../Elements/Banner";
import Whatsapp from "../Common/Whatsapp";

var bnrimg = require("./../../images/turnkey1.jpg");
var bgimg2 = require("./../../images/background/cross-line2.png");

class TrunkeyService extends React.Component {
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          <Banner title="Turnkey Solutions" pagename="Turnkey Solutions" description="Our turnkey solutions provide complete project management, handling every phase from design to construction, ensuring a smooth, stress-free experience and a fully finished property..." bgimage={bnrimg}/>
          {/* SECTION CONTENT START */}
          {/* <TrunkeySlider /> */}
          <TrunkeyAbout3 bgcolor="bg-gray" />

          <div className="main-div">
            <div className="container">
              <div className="section-head">
                <div className="sx-separator-outer separator-center">
                  <div
                    className="sx-separator bg-white bg-moving bg-repeat-x"
                    style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                  >
                    <h3 className="sep-line-one">
                    Why Choose Studio Sarva Architects for Turnkey Construction?
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-door" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">All-Inclusive Service</h4>
                      <p>
                      Our turnkey solutions cover every aspect of the construction process, providing a streamlined experience where we handle everything, from design and permits to construction and final finishing.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">
                      Time and Cost Efficiency
                      </h4>
                      <p>
                      We ensure the project stays on schedule and within your budget, without compromising on quality. Our efficient approach minimizes delays and unexpected costs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">
                      High-Quality Standards
                      </h4>
                      <p>
                      With a focus on quality craftsmanship, we ensure that each element of the construction meets the highest standards, delivering a finished product that exceeds your expectations.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Stress-Free Process</h4>
                      <p>
                      We manage all the complexities of the project, allowing you to focus on the end result while we take care of the details, ensuring a smooth, worry-free construction experience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TrunkeyAbout5 />
          <TrunkeyService7 />
          <TrunkeyStatistics />
          <TrunkeyPlans />
          {/* SECTION CONTENT END  */}
        </div>
        <Whatsapp />
        <Footer />
      </>
    );
  }
}

export default TrunkeyService;
