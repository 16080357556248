import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/services/comm.png");

class ArchConsultancyAbout3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <h3 className="m-t0 m-b20 sx-tilte">
                      Architectural Consultancy
                    </h3>
                    <p>
                      At Studio Sarva Architecture, we offer expert
                      architectural consultancy services tailored to meet the
                      unique needs of each client. Our comprehensive consultancy
                      solutions encompass all stages of the architectural
                      process, from initial concept development to detailed
                      planning and execution. Whether you need assistance with
                      design, regulatory approvals, or project management, we
                      ensure that your project benefits from professional
                      insights and careful guidance at every step.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <p className="service-details-points">
                        Customized design consultation based on client requirements.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Expert guidance on regulatory approvals and permits.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Detailed planning and feasibility studies to ensure project success.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Integration of sustainable and innovative architectural solutions.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Collaboration with skilled professionals for technical and aesthetic excellence.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ArchConsultancyAbout3;
