import React from "react";
import { NavLink } from "react-router-dom";

const services = [
  {
    count: "01",
    title: "Tailored Creativity",
    flaticon: "flaticon-sketch",
    image: require("./../../images/services/service-projects/1.png"),
    description:
      "We understand that every project is unique, and our concept designs are tailored to reflect your specific vision. Whether it's a commercial space or a hospitality venue, we create designs that align with your business identity and goals, ensuring a strong foundation for success.",
  },
  {
    count: "02",
    title: "Innovative and Sustainable Solutions",
    flaticon: "flaticon-stairs",
    image: require("./../../images/services/service-projects/2.png"),
    description:
      "Our team is skilled in blending creativity with sustainability. We incorporate the latest trends and eco-friendly practices, delivering designs that are not only visually stunning but also energy-efficient and environmentally responsible.",
  },
  {
    count: "03",
    title: "Practical Execution",
    flaticon: "flaticon-window",
    image: require("./../../images/services/service-projects/3.png"),
    description:
      "Every concept design we create is backed by thorough feasibility studies to ensure that it can be executed within the specified budget and timeline. We focus on delivering practical solutions that are achievable, without compromising on innovation or quality.",
  },
];

var bgimg1 = require("./../../images/background/cross-line2.png");

class ConceptDesignService7 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 bg-white">
          <div className="container">
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                >
                  <h3 className="sep-line-one">
                  Core Reasons to Choose Studio Sarva Architects for Concept Design
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              {services.map((item, index) => (
                <div className="col-lg-4 col-md-12 m-b30" key={index}>
                  <div
                    className="bgcall-block d-flex flex-wrap justify-content-center align-content-end bg-cover overlay-wraper"
                    style={{ backgroundImage: "url(" + item.image + ")" }}
                  >
                    <div className="overlay-main bg-black opacity-05" />
                    <div className="bg-content-mid-outer">
                      <div className="bg-content-mid">
                        <div className="sx-icon-box-wraper center   text-white">
                          <div className="icon-lg m-b15">
                            <span className="icon-cell">
                              <i className={item.flaticon} />
                            </span>
                          </div>
                          <div className="icon-content m-b30">
                            <h4 className="sx-tilte">
                              {" "}
                              <NavLink
                                to={"/services-detail"}
                                className="sx-text-white"
                              >
                                {item.title}
                              </NavLink>
                            </h4>
                            <p>{item.description}</p>
                          </div>
                        </div>
                        <span className="bgcall-block-number">
                          {item.count}
                        </span>
                        <div className="bg-overlay" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ConceptDesignService7;
