import React from "react";
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import UrbanDesignSlider from "../Elements/UrbanDesignSlider";
import UrbanDesignAbout3 from "../Elements/UrbanDesignAbout3";
import UrbanDesignAbout5 from "../Elements/UrbanDesignAbout5";
import UrbanDesignService7 from "../Elements/UrbanDesignService7";
import UrbanDesignStatistics from "../Elements/UrbanDesignSStatistics";
import UrbanDesignPlans from "../Elements/UrbanDesignPlans";
import Banner from "../Elements/Banner";
import Whatsapp from "../Common/Whatsapp";

var bnrimg = require("./../../images/urbanbanner.jpg");
var bgimg2 = require("./../../images/background/cross-line2.png");

class UrbanDesign extends React.Component {
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          <Banner title="Urban Design & Development" pagename="Urban Design & Development" description="Our urban design and development services focus on sustainable and community-centric spaces, transforming cities with innovative designs that prioritize functionality and aesthetic appeal..." bgimage={bnrimg}/>
          {/* SECTION CONTENT START */}
          {/* <UrbanDesignSlider /> */}
          <UrbanDesignAbout3 bgcolor="bg-gray" />

          <div className="main-div">
            <div className="container">
              <div className="section-head">
                <div className="sx-separator-outer separator-center">
                  <div
                    className="sx-separator bg-white bg-moving bg-repeat-x"
                    style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                  >
                    <h3 className="sep-line-one">
                      Why Choose Studio Sarva Architects for Urban Design and
                      Development?
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-door" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Holistic Planning Approach</h4>
                      <p>
                        We take a comprehensive approach to urban design,
                        considering all aspects of the environment,
                        infrastructure, and community needs to create spaces
                        that work in harmony with their surroundings.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">
                        Sustainable and Smart Development
                      </h4>
                      <p>
                        Our urban design services prioritize sustainability,
                        incorporating green building techniques,
                        energy-efficient infrastructure, and eco-friendly
                        materials to reduce environmental impact.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Community-Centered Design</h4>
                      <p>
                        We focus on creating spaces that foster community
                        interaction, social inclusion, and accessibility. Our
                        designs include vibrant public spaces and infrastructure
                        that encourages a sense of belonging.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Collaborative Process</h4>
                      <p>
                        We engage with stakeholders, including local
                        governments, developers, and community members, ensuring
                        that the urban spaces we design reflect the needs and
                        aspirations of the people who use them.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UrbanDesignAbout5 />
          <UrbanDesignService7 />
          <UrbanDesignStatistics />
          <UrbanDesignPlans />
          {/* SECTION CONTENT END  */}
        </div>
        <Whatsapp />
        <Footer />
      </>
    );
  }
}

export default UrbanDesign;
