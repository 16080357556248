import React from 'react'

const Whatsapp = () => {
  return (
    <div class="floating_btn">
    <a target="_blank" href="https://wa.me/+917760949449">
      <div class="contact_icon">
        <img className='whatsapp-png' src={require('./../../images/whatsapp-icon.png')} alt="whatsapp icon" />
      </div>
    </a>
    <p class="text_icon">Talk to us?</p>
  </div>
  )
}

export default Whatsapp