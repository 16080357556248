import React from "react";
import { NavLink } from "react-router-dom";

const services = [
  {
    count: "01",
    title: "Tailored Solutions",
    flaticon: "flaticon-sketch",
    image: require("./../../images/services/service-projects/1.png"),
    description:
      "We understand that the hospitality industry requires unique approaches to design and construction. Our team collaborates closely with you to create custom solutions that enhance your property’s appeal and functionality, ensuring a memorable guest experience.",
  },
  {
    count: "02",
    title: "Time and Cost Efficiency",
    flaticon: "flaticon-stairs",
    image: require("./../../images/services/service-projects/2.png"),
    description:
      "Our efficient project management practices guarantee that your hospitality construction is completed on schedule and within budget. We focus on minimizing delays and unexpected costs while maintaining the highest quality standards.",
  },
  {
    count: "03",
    title: "Stress-Free Experience",
    flaticon: "flaticon-window",
    image: require("./../../images/services/service-projects/3.png"),
    description:
      "By overseeing every detail of the construction process, we eliminate the complexities and stresses often associated with hospitality projects. This allows you to concentrate on your core business while we manage the construction, delivering a fully operational venue.",
  },
];

var bgimg1 = require("./../../images/background/cross-line2.png");

class HospitalityService7 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 bg-white">
          <div className="container">
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                >
                  <h3 className="sep-line-one">
                  Core Reasons to Choose Studio Sarva Architects
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              {services.map((item, index) => (
                <div className="col-lg-4 col-md-12 m-b30" key={index}>
                  <div
                    className="bgcall-block d-flex flex-wrap justify-content-center align-content-end bg-cover overlay-wraper"
                    style={{ backgroundImage: "url(" + item.image + ")" }}
                  >
                    <div className="overlay-main bg-black opacity-05" />
                    <div className="bg-content-mid-outer">
                      <div className="bg-content-mid">
                        <div className="sx-icon-box-wraper center   text-white">
                          <div className="icon-lg m-b15">
                            <span className="icon-cell">
                              <i className={item.flaticon} />
                            </span>
                          </div>
                          <div className="icon-content m-b30">
                            <h4 className="sx-tilte">
                              {" "}
                              <NavLink
                                to={"/services-detail"}
                                className="sx-text-white"
                              >
                                {item.title}
                              </NavLink>
                            </h4>
                            <p>{item.description}</p>
                          </div>
                        </div>
                        <span className="bgcall-block-number">
                          {item.count}
                        </span>
                        <div className="bg-overlay" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HospitalityService7;
