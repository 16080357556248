import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const filters = [
    { label: "Architecture", filter: ".cat-1" },
    { label: "Interiors", filter: ".cat-4" },
    { label: "Residential", filter: ".cat-5" },
    { label: "Commercial", filter: ".cat-3" },
    { label: "Institutional", filter: ".cat-6" },
    { label: "Health Care", filter: ".cat-7" },
    { label: "Urban Development", filter: ".cat-7" },
    { label: "Hospitality", filter: ".cat-2" }
];

const projects = [
    {
        image: require('./../../images/projects/guptaresidency/gupta.png'),
        title: 'Gupta Residency',
        slug: 'gupta-residency',
        description: 'We combine Interior and Exterior Design services and often provide...',
        filter: 'cat-5'
    },
    {
        image: require('./../../images/projects/jaswanth/jaswanth.png'),
        title: 'Jaswanth Residency',
        slug: 'jaswanth-residency',
        description: 'Landscape plans for drainage problems may also entail planting.',
        filter: 'cat-5'
    },
    {
        image: require('./../../images/projects/koushik/koushik.png'),
        title: 'Koushik Residency',
        slug: 'koushik-residency',
        description: 'We provide a range of architectural 3D modeling services to our customers...',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/projects/nagesh/nagesh.png'),
        title: 'Nagesh Residency',
        slug: 'nagesh-residency',
        description: 'Landscape plans for drainage problems may also entail planting beds away ...',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/projects/shruthi/shruthi.png'),
        title: 'Shruthi Residency',
        slug: 'shruthi-residency',
        description: 'Project management is the process by which our team plans and executes...',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/projects/aerospace/aerospace.png'),
        title: 'TM Aerospace',
        slug: 'tm-aerospace',
        description: 'Our team also provides consultations on all architectural issues, even if you need...',
        filter: 'cat-3'
    },
    // {
    //     image: require('./../../images/projects/portrait/pic9.jpg'),
    //     title: 'Living Room',
    //     slug: 'slug7',
    //     description: 'Landscape plans for drainage problems may also entail planting beds...',
    //     filter: 'cat-3'
    // },
    // {
    //     image: require('./../../images/projects/square/pic2.jpg'),
    //     title: 'Wall Interior',
    //     slug: 'slug8',
    //     description: 'We combine Interior and Exterior Design services and often provide...',
    //     filter: 'cat-2'
    // },
    // {
    //     image: require('./../../images/projects/portrait/pic2.jpg'),
    //     title: 'Dream Home',
    //     slug: 'slug9',
    //     description: 'We combine Interior and Exterior Design services and often provide...',
    //     filter: 'cat-3'
    // },

    // {
    //     image: require('./../../images/projects/portrait/pic3.jpg'),
    //     title: 'Queens Museum',
    //     slug: 'slug10',
    //     description: 'We combine Interior and Exterior Design services and often provide...',
    //     filter: 'cat-3'
    // },
    // {
    //     image: require('./../../images/projects/square/pic1.jpg'),
    //     title: 'Life style building',
    //     slug: 'slug11',
    //     description: 'Project management is the process by which our team plans and executes...',
    //     filter: 'cat-1'
    // },
    // {
    //     image: require('./../../images/projects/square/pic3.jpg'),
    //     title: 'Hotel Shears',
    //     slug: 'slug12',
    //     description: 'Landscape plans for drainage problems may also entail planting beds...',
    //     filter: 'cat-2'
    // },
    // {
    //     image: require('./../../images/projects/portrait/pic4.jpg'),
    //     title: 'Modern Bathroom',
    //     slug: 'slug13',
    //     description: 'We combine Interior and Exterior Design services and often provide...',
    //     filter: 'cat-1'
    // },
    // {
    //     image: require('./../../images/projects/square/pic9.jpg'),
    //     title: 'Dream House',
    //     slug: 'slug14',
    //     description: 'Landscape plans for drainage problems may also entail planting beds...',
    //     filter: 'cat-2'
    // },
    // {
    //     image: require('./../../images/projects/square/pic10.jpg'),
    //     title: 'Life style building',
    //     slug: 'slug14',
    //     description: 'Project management is the process by which our team plans and executes...',
    //     filter: 'cat-3'
    // }
]

var bgimg1 = require('./../../images/background/cross-line2.png');

class Projects1 extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {
        const options = {
            loop:false,
            autoplay:false,
            margin:30,
            nav:true,
            dots: false,
            navText: ['Prev', 'Next'],
            responsive:{
                0:{
                    items:1,
                },
                540:{
                    items:2,
                },
                768:{
                    items:3,
                },			
                991:{
                    items:3
                },
                1136:{
                    items:4
                },					
                1366:{
                    items:5
                }	
                }
        };
        return (
            <>
                <div className="section-full mobile-page-padding  p-t80 p-b50 bg-white">
                    <div className="section-content">
                        <div className="container">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="sx-separator-outer separator-center">
                                    <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                        <h3 className="sep-line-one">All Projects</h3>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                        </div>
                        <div className=" filter-carousal-1-outer relative z-index1">
                            <div className="container">
                                {/* TITLE START */}
                                <div className="text-center clearfix filter-pos-right shadow m-b30">
                                    <ul className="btn-filter-wrap">
                                        <li className="btn-filter btn-active" data-filter="*">All</li>
                                        {filters.map((item, index) => (
                                            <li key={index} className="btn-filter" data-filter={item.filter}>{item.label}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="filter-carousal-1  container-fluid p-b30">
                                {/* IMAGE CAROUSEL START */}
                                <div className="section-content ">
                                    <OwlCarousel className="owl-carousel owl-carousel-filter  owl-btn-bottom-center mfp-gallery navigation-with-name" {...options}>
                                        {projects.map((item, index) => (
                                            <div key={index} className={`${item.filter} item fadingcol overflow-hide`}>
                                                <div className="sx-box   image-hover-block">
                                                    <div className="sx-thum-bx">
                                                        <img src={item.image} alt="" />
                                                    </div>
                                                    <div className="sx-info  p-t20 text-white">
                                                        <h4 className="sx-tilte"><NavLink to={`/project/${item.slug}`}>{item.title}</NavLink></h4>
                                                        <p className="m-b0">{item.address}</p>
                                                    </div>
                                                    <a className="mfp-link" href={item.image}>
                                                        <i className="fa fa-arrows-alt" />
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Projects1;