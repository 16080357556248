import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Header4 from './../Common/Header4';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import Projects7 from './../Elements/Projects7';
import ReactPlayer from 'react-player';

var bnrimg = require('./../../images/banner/1.jpg');

const GuptaResidency = () => {

  return (
    <>
      <Header4 />
      <div className="page-content">
        <Banner 
          title='Gupta Residency'
          pagename="Gupta Residency" 
          description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized." 
          bgimage={bnrimg} 
        />

        {/* SECTION CONTENT START */}
        <div className="section-full p-tb80 inner-page-padding stick_in_parent">
          <div className="container">
            <div className="row">
            <div className="col-lg-4 col-md-12  sticky_column">
                <div className="project-detail-containt-2">
                  <div className="bg-white text-black p-a20 shadow">
                    <div className="product-block bg-gray p-a30 m-b30">
                      <ul>
                        <li>
                          <h4 className="sx-title">Date</h4>
                          <p>2023-01-01</p>
                        </li>
                        <li>
                          <h4 className="sx-title">Client</h4>
                          <p>Client A</p>
                        </li>
                        <li>
                          <h4 className="sx-title">Project type</h4>
                          <p>Residential</p>
                        </li>
                        <li>
                          <h4 className="sx-title">Location</h4>
                          <p>Location A</p>
                        </li>
                        <li>
                          <h4 className="sx-title">Year</h4>
                          <p>2023</p>
                        </li>
                      </ul>
                    </div>
                    <h4>We combine Interior and Exterior Design services and often provide...</h4>
                    <p>We combine Interior and Exterior Design services and often provide...</p>
                    <div className="m-b0">
                      <div className="sx-divider divider-1px  bg-black"><i className="icon-dot c-square" /></div>
                    </div>
                    <ul className="social-icons social-square social-darkest m-b0">
                      <li><a href="https://www.facebook.com" target="_blank" className="fa fa-facebook" /></li>
                      <li><a href="https://twitter.com" target="_blank" className="fa fa-twitter" /></li>
                      <li><a href="https://in.linkedin.com" target="_blank" className="fa fa-linkedin" /></li>
                      <li><a href="https://rss.com" target="_blank" className="fa fa-rss" /></li>
                      <li><a href="https://www.youtube.com" target="_blank" className="fa fa-youtube" /></li>
                      <li><a href="https://www.instagram.com" target="_blank" className="fa fa-instagram" /></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="project-detail-outer row">
                  {/* You can map the project images here */}
                  {/* {project?.images && project?.images.map((imgSrc, index) => ( */}
                    <div className="col-md-6">
                      <div className="project-detail-pic m-b30">
                        <div className="sx-media">
                          <img src='/projects/guptaresidency/1.jpg' alt={`Project image`} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="project-detail-pic m-b30">
                        <div className="sx-media">
                          <img src='/projects/guptaresidency/2.jpg' alt={`Project image`} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="project-detail-pic m-b30">
                        <div className="sx-media">
                          <img src='/projects/guptaresidency/3.jpg' alt={`Project image`} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="project-detail-pic m-b30">
                        <div className="sx-media">
                          <img src='/projects/guptaresidency/4.jpg' alt={`Project image`} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="project-detail-pic m-b30">
                        <div className="sx-media">
                          <img src='/projects/guptaresidency/5.jpg' alt={`Project image`} />
                        </div>
                      </div>
                    </div>
                  {/* ))} */}
                  
                  <div className="col-md-12">
                    <div className="sx-box">
                      <div className="sx-thum-bx sx-img-overlay1 sx-img-effect yt-thum-box">
                        <img src="https://img.youtube.com/vi/Oy2QIiSQT2U/0.jpg" alt="" />
                        <NavLink to={"#"} className="play-now" data-toggle="modal" data-target="#myModal">
                          <i className="icon fa fa-play" />
                          <span className="ripple" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12  sticky_column">
                <div className="project-detail-containt-2">
                  <div className="bg-white text-black p-a20 shadow">
                    <div className="product-block bg-gray p-a30 m-b30">
                      <ul>
                        <li>
                          <h4 className="sx-title">Date</h4>
                          <p>2023-01-01</p>
                        </li>
                        <li>
                          <h4 className="sx-title">Client</h4>
                          <p>Client A</p>
                        </li>
                        <li>
                          <h4 className="sx-title">Project type</h4>
                          <p>Residential</p>
                        </li>
                        <li>
                          <h4 className="sx-title">Location</h4>
                          <p>Location A</p>
                        </li>
                        <li>
                          <h4 className="sx-title">Year</h4>
                          <p>2023</p>
                        </li>
                      </ul>
                    </div>
                    <h4>We combine Interior and Exterior Design services and often provide...</h4>
                    <p>We combine Interior and Exterior Design services and often provide...</p>
                    <div className="m-b0">
                      <div className="sx-divider divider-1px  bg-black"><i className="icon-dot c-square" /></div>
                    </div>
                    <ul className="social-icons social-square social-darkest m-b0">
                      <li><a href="https://www.facebook.com" target="_blank" className="fa fa-facebook" /></li>
                      <li><a href="https://twitter.com" target="_blank" className="fa fa-twitter" /></li>
                      <li><a href="https://in.linkedin.com" target="_blank" className="fa fa-linkedin" /></li>
                      <li><a href="https://rss.com" target="_blank" className="fa fa-rss" /></li>
                      <li><a href="https://www.youtube.com" target="_blank" className="fa fa-youtube" /></li>
                      <li><a href="https://www.instagram.com" target="_blank" className="fa fa-instagram" /></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-detail-containt-2 m-t50">
              <h3>
                Creating a sustainable future through building preservation,
                green architecture, and smart design
              </h3>
              <p className="m-b0">
                {" "}
                Designers think everything done by someone else is awful, and
                that they could do it better themselves, which explains why I
                designed my own living room carpet, I suppose. the architect
                represents neither a Dionysian nor an Apollinian condition: here
                it is the mighty act of will, the will which moves mountains,
                the intoxication of the strong will, which demands artistic
                expression. The most powerful men have always inspired the
                architects; the architect has always been influenced by power.
              </p>
            </div>
          </div>
        </div>
        {/* SECTION CONTENT END */}
        <Projects7 />
      </div>

      <div className="modal fade" id="myModal2" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <ReactPlayer url='https://www.youtube.com/watch?v=Oy2QIiSQT2U' />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GuptaResidency;