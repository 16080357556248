import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/services/commercial2.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class ArchConsultancyAbout5 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">
                    Excellence in Architectural Consultancy
                  </h3>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <p>
                      At Studio Sarva Architects, we offer full-scale
                      architectural consultancy services, providing expert
                      advice and solutions to ensure the successful realization
                      of your project. Our services cover all aspects of
                      architectural planning and development, from creative
                      design solutions to project management. We are dedicated
                      to delivering high-quality results that align with your
                      vision while maintaining efficiency, sustainability, and
                      regulatory compliance.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <p className="service-details-points">
                        Comprehensive consultation, from concept to project completion.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Feasibility studies and detailed project planning for informed decisions.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Sustainable design integration and innovative architectural solutions.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Clear and regular communication to ensure alignment with your goals.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Expert assistance in regulatory approvals and construction documentation.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ArchConsultancyAbout5;
