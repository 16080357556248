import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/services/comm.png");

class CommercialAbout3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <h3 className="m-t0 m-b20 sx-tilte">
                      Commercial Construction
                    </h3>
                    <p>
                      At Studio Sarva Architecture, we specialize in providing
                      comprehensive commercial construction solutions tailored
                      to meet the unique needs of businesses. From office spaces
                      to retail environments, we manage every phase of the
                      construction process to deliver functional, aesthetically
                      pleasing, and efficient spaces that enhance productivity
                      and brand identity. Our commercial construction services
                      are designed for clients who seek quality, reliability,
                      and expertise, ensuring a smooth and successful project
                      from start to finish.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <p className="service-details-points">
                        Full-scale project management for commercial builds.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Customized designs to align with your business vision.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Use of high-quality materials and innovative construction methods.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Timely completion with a focus on operational efficiency.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Consistent communication and collaboration throughout the project.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CommercialAbout3;
