import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../images/background/bg-4.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class UrbanDesignPlans extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full text-center bg-gray bg-no-repeat p-t80 p-b80 bg-top-right mobile-page-padding"
          tyle={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">
                    Studio Sarva Architects – Leading Urban Design and
                    Development Firm in Bangalore
                  </h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="pricingtable-row p-t20">
                <p className="service-last-section">
                  Studio Sarva Architects is recognized for delivering
                  outstanding urban design and development services in
                  Bangalore. Our expertise in master planning, sustainable
                  infrastructure, and community-focused design ensures that our
                  urban projects are functional, aesthetically pleasing, and
                  environmentally responsible. We collaborate with local
                  authorities, developers, and communities to design spaces that
                  meet the needs of today while preparing for the demands of
                  tomorrow. With a commitment to quality, sustainability, and
                  social impact, Studio Sarva Architects is your trusted partner
                  in urban development. Choose us for your next urban design
                  project and experience a thoughtful approach to building
                  better cities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UrbanDesignPlans;
