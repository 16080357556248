import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../images/background/bg-4.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class InteriorDesignPlans extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full text-center bg-gray bg-no-repeat p-t80 p-b80 bg-top-right mobile-page-padding"
          tyle={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">
                    Studio Sarva Architects – Top Interior Design Firm in
                    Bangalore
                  </h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="pricingtable-row p-t20">
                <p className="service-last-section">
                  Studio Sarva Architects is recognized as one of the top
                  interior design firms in Bangalore, offering customized
                  solutions that transform spaces into beautiful, functional
                  environments. Our expertise spans residential, corporate, and
                  commercial interiors, and we focus on creating personalized,
                  high-quality designs that reflect the client’s vision. With a
                  commitment to using sustainable materials and modern design
                  techniques, we deliver interiors that are not only stylish but
                  also eco-friendly and practical. Trust Studio Sarva Architects
                  for a seamless and creative interior design experience that
                  brings your vision to life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default InteriorDesignPlans;
