import React from 'react';
import Footer from './../Common/Footer';
import About1 from './../Elements/About1';
import WhatWeDo1 from './../Elements/WhatWeDo1';
import Testimonials1 from './../Elements/Testimonials1';
import Services1 from './../Elements/Services1';
import Projects1 from './../Elements/Projects1';
import Header4 from '../Common/Header4';
import Blog1 from '../Elements/Blog1';
import ContactUs2 from './ContactUs2';
import Slider2 from '../Elements/Slider2';
import Whatsapp from '../Common/Whatsapp';

class Home1 extends React.Component {
    render() {
        return (
            <>
                <Header4 />
                <div className="page-content">
                    <Slider2 />
                    <About1 />
                    <WhatWeDo1 />
                    <Testimonials1 />
                    <Services1 />
                    <Projects1 />
                    {/* <ClientsLogo1 /> */}
                    {/* <Team1 /> */}
                    <Blog1 />
                    <ContactUs2 />
                </div>
                <Whatsapp />
                <Footer />
            </>
        );
    };
};

export default Home1;