import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/turnkey2.png");

class TrunkeyAbout3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <h3 className="m-t0 m-b20 sx-tilte">
                      Turnkey Construction
                    </h3>
                    <p>
                      At Studio Sarva Architecture, we offer complete turnkey
                      construction solutions that take the hassle out of
                      building your dream space. From the initial concept to the
                      final handover, we manage every phase of the project,
                      ensuring you receive a fully completed, ready-to-use
                      property. Our turnkey services are designed for clients
                      who value efficiency, convenience, and high-quality
                      results, with a focus on delivering a seamless and
                      stress-free experience.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <p className="service-details-points">
                        Complete project management from start to finish.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Single point of contact for all construction needs
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        High-quality materials and modern construction techniques.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        On-time delivery with a focus on efficiency and precision.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Transparent communication and full accountability throughout the process.
                        </p>
                      </li>
                    </ul>
                    {/* <div className="text-left">
                      <p  className="site-button btn-half">
                        <span>Read More</span>
                      </p>
                    </div> */}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content">
                          {/* <NavLink to={"#"} className="play-now" data-toggle="modal" data-target="#myModal">
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </NavLink>

                                                    <div className="video-section-bottom">
                                                        <h3 className="sx-title text-white">25 Years<br />Experience</h3>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <ReactPlayer url="https://vimeo.com/34741214" />
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

export default TrunkeyAbout3;
