import React from "react";
import { NavLink } from "react-router-dom";

const services = [
  {
    count: "01",
    title: "Customized Architectural Solutions",
    flaticon: "flaticon-sketch",
    image: require("./../../images/services/service-projects/1.png"),
    description:
      "We provide personalized consultancy services tailored to your project’s unique requirements, from initial design concepts to execution strategies.",
  },
  {
    count: "02",
    title: "Expertise and Insight",
    flaticon: "flaticon-stairs",
    image: require("./../../images/services/service-projects/2.png"),
    description:
      "Our experienced team offers professional insights that ensure every architectural detail is carefully considered, from aesthetics to compliance with regulations.",
  },
  {
    count: "03",
    title: "Efficient Project Coordination",
    flaticon: "flaticon-window",
    image: require("./../../images/services/service-projects/3.png"),
    description:
      "Our consultancy services provide effective coordination across all teams, ensuring seamless execution of your project, with no delays or miscommunication.",
  },
];

var bgimg1 = require("./../../images/background/cross-line2.png");

class ArchConsultancyService7 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 bg-white">
          <div className="container">
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                >
                  <h3 className="sep-line-one">
                  Core Reasons to Choose Studio Sarva Architects for Consultancy
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              {services.map((item, index) => (
                <div className="col-lg-4 col-md-12 m-b30" key={index}>
                  <div
                    className="bgcall-block d-flex flex-wrap justify-content-center align-content-end bg-cover overlay-wraper"
                    style={{ backgroundImage: "url(" + item.image + ")" }}
                  >
                    <div className="overlay-main bg-black opacity-05" />
                    <div className="bg-content-mid-outer">
                      <div className="bg-content-mid">
                        <div className="sx-icon-box-wraper center   text-white">
                          <div className="icon-lg m-b15">
                            <span className="icon-cell">
                              <i className={item.flaticon} />
                            </span>
                          </div>
                          <div className="icon-content m-b30">
                            <h4 className="sx-tilte">
                              {" "}
                              <NavLink
                                to={"/services-detail"}
                                className="sx-text-white"
                              >
                                {item.title}
                              </NavLink>
                            </h4>
                            <p>{item.description}</p>
                          </div>
                        </div>
                        <span className="bgcall-block-number">
                          {item.count}
                        </span>
                        <div className="bg-overlay" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ArchConsultancyService7;
