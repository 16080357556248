import React from "react";
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import HospitalitySlider from "../Elements/HospitalitySlider";
import HospitalityAbout3 from "../Elements/HospitalityAbout3";
import HospitalityAbout5 from "../Elements/HospitalityAbout5";
import HospitalityService7 from "../Elements/HospitalityService7";
import HospitalityStatistics from "../Elements/HospitalityStatistics";
import HospitalityPlans from "../Elements/HospitalityPlans";
import Whatsapp from "../Common/Whatsapp";

var bnrimg = require("./../../images/banner/4.jpg");
var bgimg2 = require("./../../images/background/cross-line2.png");

class HospitatlityConstruction extends React.Component {
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          {/* <Banner title="Service Details" pagename="Service Detail" description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized." bgimage={bnrimg}/> */}
          {/* SECTION CONTENT START */}
          <HospitalitySlider />
          <HospitalityAbout3 bgcolor="bg-gray" />

          <div className="main-div">
            <div className="container">
              <div className="section-head">
                <div className="sx-separator-outer separator-center">
                  <div
                    className="sx-separator bg-white bg-moving bg-repeat-x"
                    style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                  >
                    <h3 className="sep-line-one">
                    Why Choose Studio Sarva Architects for Hospitality Construction?
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-door" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Customized for Guest Experience</h4>
                      <p>
                      We know that hospitality spaces must be welcoming and functional. Our team works closely with you to develop designs that align with your brand, ensuring a space that enhances guest satisfaction and reflects the unique qualities of your business.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">
                      Seamless Project Execution
                      </h4>
                      <p>
                      We handle all phases of the construction process—from concept and design to execution and final touches—ensuring your project is completed on time and within budget, without sacrificing quality or style.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">
                      Attention to Detail
                      </h4>
                      <p>
                      With an emphasis on superior craftsmanship and high-quality materials, we deliver hospitality spaces that are not only aesthetically stunning but also durable and built to stand the test of time.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Guest-Centric Solutions</h4>
                      <p>
                      By focusing on the needs of your guests, we create environments that maximize comfort, functionality, and efficiency, allowing your hospitality business to thrive.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HospitalityAbout5 />
          <HospitalityService7 />
          <HospitalityStatistics />
          <HospitalityPlans />
          {/* SECTION CONTENT END  */}
        </div>
        <Whatsapp />
        <Footer />
      </>
    );
  }
}

export default HospitatlityConstruction;
