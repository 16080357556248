import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/services/concept.png");

class ConceptDesignAbout3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <h3 className="m-t0 m-b20 sx-tilte">Concept Design</h3>
                    <p>
                      At Studio Sarva Architecture, we specialize in delivering
                      visionary concept designs that form the foundation of
                      extraordinary spaces. Whether you’re looking to build a
                      commercial establishment, hospitality venue, or a unique
                      architectural masterpiece, our concept designs reflect
                      creativity, innovation, and functionality. We collaborate
                      closely with our clients to bring their ideas to life,
                      ensuring that every design aligns with their business
                      goals, brand identity, and vision for the future.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <p className="service-details-points">
                        Comprehensive design strategy tailored to your project’s goals.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Innovative and aesthetically captivating concepts.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Emphasis on functionality, sustainability, and modern trends.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Collaborative design process with client input at every stage.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Feasibility studies to ensure a realistic and achievable design vision.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ConceptDesignAbout3;
