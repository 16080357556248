import React from 'react';

const achievements1 = [
    {
        title: 'Holistic Approach',
        description: 'With expertise across construction, design, and interiors, we bring a comprehensive approach that ensures every element of your project works in harmony',
        date: '01',
        month: 'Oct',
        year: '2013',
    },
    {
        title: 'Client-Centered Process',
        description: 'We believe in collaboration and transparency. Your vision is at the center of our process, and we make sure you are involved and informed at every step.',
        date: '20',
        month: 'Mar',
        year: '2014',
    },
    {
        title: 'Skilled & Experienced Team',
        description: 'Our team consists of highly skilled architects, designers, and construction professionals with years of experience across diverse projects. From modern minimalism to classic elegance, we have the expertise to bring any style to life.',
        date: '19',
        month: 'July',
        year: '2017',
    },
    
    // {
    //     title: 'Newyork Creative Art',
    //     description: 'Every work has its professional which can perform their task with all the best standards.',
    //     date: '20',
    //     month: 'Oct',
    //     year: '2015',
    // }
]

const achievements2 = [
    {
        title: 'Quality & Innovation',
        description: 'Sarva Studio is committed to delivering high-quality results through innovative design solutions. We stay at the forefront of industry trends while prioritizing timeless appeal.',
        date: '28',
        month: 'Aug',
        year: '2014',
    },
    {
        title: 'Sustainability Focus',
        description: 'We incorporate sustainable practices into our designs and construction processes, selecting eco-friendly materials and energy-efficient solutions wherever possible to reduce the environmental impact and enhance long-term efficiency.',
        date: '27',
        month: 'Nov',
        year: '2015',
    },
    {
        title: 'Tailored Solutions',
        description: 'Every project is unique, and so are our solutions. We take the time to understand your specific needs, preferences, and lifestyle, offering bespoke designs and construction plans that fit your vision and budget.',
        date: '07',
        month: 'Mar',
        year: '2016',
    },
    
    
    // {
    //     title: 'Center of Gloucester',
    //     description: 'Scrambled it to make a type specimen book. remaining essentially. ',
    //     date: '27',
    //     month: 'May',
    //     year: '2018',
    // }
]

var bgimg1 = require('./../../images/background/bg-map.png');
var bgimg2 = require('./../../images/background/cross-line2.png');

class Achievements1 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 sx-bg-secondry bg-no-repeat bg-bottom-center mobile-page-padding" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                    <h3 className="sep-line-one">Why Choose Sarva Studio</h3>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content ">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12 m-b30">
                                    {achievements1.map((item, index) => (
                                        <div className="sx-box our-story text-white" key={index}>
                                            {/* <div className="mt-media our-story-info d-flex justify-content-start">
                                                <div className="our-story-time"><span className="date">{item.date}</span><span className="month">{item.month}</span></div>
                                                <h4 className="text-uppercase our-story-year">{item.year}</h4>
                                            </div> */}
                                            <div className="our-story-detail">
                                                <h4>{item.title}</h4>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                    {achievements2.map((item, index) => (
                                        <div className="sx-box our-story text-white" key={index}>
                                            {/* <div className="mt-media our-story-info d-flex justify-content-start">
                                                <div className="our-story-time"><span className="date">{item.date}</span><span className="month">{item.month}</span></div>
                                                <h4 className="text-uppercase our-story-year">{item.year}</h4>
                                            </div> */}
                                            <div className="our-story-detail">
                                                <h4>{item.title}</h4>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Achievements1;