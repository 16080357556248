import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/turnkey2.jpg");
var bgimg2 = require("./../../images/background/cross-line2.png");

class TrunkeyAbout5 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">
                    Excellence in Turnkey Construction
                  </h3>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <p>
                      At Studio Sarva Architects, we deliver comprehensive
                      turnkey construction solutions, offering an all-in-one
                      service that handles every detail of your project, from
                      concept to completion. Our team is dedicated to providing
                      high-quality, efficient, and sustainable construction
                      services. We ensure a hassle-free experience by managing
                      all aspects of the project, so you can focus on enjoying
                      the final product. With a commitment to precision and
                      client satisfaction, we turn your vision into reality with
                      minimal stress and maximum quality.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <p className="service-details-points">
                        Complete design-to-delivery project management.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Efficient use of high-quality materials and cutting-edge techniques.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Seamless execution with no delays or hidden costs.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Customized designs to meet your specific needs
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Clear and consistent communication at every stage
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TrunkeyAbout5;
