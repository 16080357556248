import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/video-bg.jpg");
var bgimg2 = require("./../../images/background/cross-line2.png");

class HospitalityAbout5 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">
                    Excellence in Hospitality Construction
                  </h3>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <p>
                      At Studio Sarva Architects, we specialize in delivering
                      outstanding hospitality construction solutions tailored to
                      the unique needs of hotels, resorts, and other hospitality
                      venues. Our comprehensive services encompass every phase
                      of the construction process, from initial design concepts
                      to final handover, ensuring that your project meets the
                      highest standards of quality, functionality, and aesthetic
                      appeal. Our team of experts is dedicated to creating
                      inviting, innovative spaces that enhance guest experiences
                      and align with your brand’s vision. With a commitment to
                      excellence, efficiency, and sustainability, we turn your
                      hospitality dreams into reality, providing beautifully
                      crafted environments that welcome guests and foster
                      unforgettable experiences.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <p className="service-details-points">
                        End-to-end project management for hospitality developments.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Custom designs that reflect your brand identity and guest experience.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Use of high-quality materials and advanced construction techniques.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Timely delivery with a focus on maintaining budget constraints.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Consistent communication and collaboration throughout the project lifecycle.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HospitalityAbout5;
