import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: '01',
        title: 'Architectural Consultancy',
        link: '/architectural-consultancy',
        flaticon: 'flaticon-sketch',
        description: 'Our architectural consultancy offers expert guidance from concept development to construction, ensuring innovative, sustainable, and aesthetically pleasing designs that meet your vision...',
    },
    {
        count: '02',
        title: 'Turnkey Solutions',
        link: '/turnkey-projects',
        flaticon: 'flaticon-house',
        description: 'Our turnkey solutions provide complete project management, handling every phase from design to construction, ensuring a smooth, stress-free experience and a fully finished property...',
    },
    {
        count: '03',
        title: 'Interior Design',
        link: '/interior-design',
        flaticon: 'flaticon-door',
        description: 'We create personalized, stylish, and functional interior spaces that reflect your lifestyle or business, combining aesthetics with practicality for a truly inspiring environment...',
    },
    {
        count: '04',
        title: 'Urban Design & Development',
        link: '/urban-design',
        flaticon: 'flaticon-skyline',
        description: 'Our urban design and development services focus on sustainable and community-centric spaces, transforming cities with innovative designs that prioritize functionality and aesthetic appeal...',
    }
    
    
]

var bgimg1 = require('./../../images/background/cross-line2.png');

class Services1 extends React.Component {
    render() {

        return (
            <>
                <div className="section-full mobile-page-padding p-t80  p-b50 bg-gray">
                    <div className="section-content">
                        <div className="container">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="sx-separator-outer separator-left">
                                    <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                        <h3 className="sep-line-one ">All Services</h3>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="row">
                                {services.map((item, index) => (
                                    <div className="col-lg-6 col-md-6 col-sm-12 m-b30" key={index}>
                                        <div className="sx-icon-box-wraper  icon-count-2-outer">
                                            <div className="icon-count-2 bg-white">
                                                <span className="icon-count-number">{item.count}</span>
                                                <div className="icon-xl inline-icon m-b5 scale-in-center">
                                                    <span className="icon-cell"><i className={item.flaticon} /></span>
                                                </div>
                                                <div className="icon-content">
                                                    <h4 className="sx-tilte">{item.title}</h4>
                                                    <p>{item.description}</p>
                                                    <div className="text-left">
                                                        <NavLink to={item.link} className="site-button-link">Read More</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase">
                        <strong>Services</strong>
                    </div>
                </div>
            </>
        );
    }
};

export default Services1;