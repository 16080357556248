import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../images/background/bg-4.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class ArchConsultancyPlans extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full text-center bg-gray bg-no-repeat p-t80 p-b80 bg-top-right mobile-page-padding"
          tyle={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">
                    Studio Sarva Architects – Leading Architectural Consultancy
                    in Bangalore
                  </h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="pricingtable-row p-t20">
                <p className="service-last-section">
                  Recognized as one of the top architectural consultancy firms
                  in Bangalore, Studio Sarva Architects provides end-to-end
                  solutions that ensure the success of your project. Our
                  consultancy services are designed to offer professional
                  guidance, innovative design strategies, and sustainable
                  practices tailored to each client’s vision. With a focus on
                  client satisfaction, regulatory compliance, and effective
                  project management, we transform architectural concepts into
                  beautifully designed spaces that meet the highest standards.
                  Choose Studio Sarva Architects for your architectural
                  consultancy needs and experience a seamless journey from
                  concept to completion.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ArchConsultancyPlans;
