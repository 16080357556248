import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/services/1.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class About5 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">
                    Excellence in Residential Construction
                  </h3>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    {/* <h3 className="m-t0 m-b20 sx-tilte">
                      Excellence in Residential Construction.
                    </h3> */}
                    <p>
                      At Studio Sarva Architects, we pride ourselves on
                      delivering high-quality, innovative, and sustainable
                      residential construction services. Our commitment to
                      client satisfaction and attention to detail ensures that
                      every home we build meets the highest standards of design
                      and functionality. We work closely with our clients to
                      bring their dream homes to life, while ensuring a seamless
                      and efficient construction process.
                    </p>
                    <ul className="list-angle-right anchor-line">
                    <li>
                        <p className="service-details-points">
                        Bespoke home designs tailored to your lifestyle.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Sustainable and eco-friendly construction practices.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Smooth project management from start to finish.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Focus on quality materials and craftsmanship.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Transparent communication throughout the project.
                        </p>
                      </li>
                    </ul>
                    {/* <div className="text-left">
                      <NavLink to="/about-1" className="site-button btn-half">
                        <span>Read More</span>
                      </NavLink>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About5;
