import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/interior1.png");

class InteriorDesignAbout3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <h3 className="m-t0 m-b20 sx-tilte">Interior Design</h3>
                    <p>
                      At Studio Sarva Architecture, we offer bespoke interior
                      design services that transform spaces into functional,
                      aesthetically pleasing environments tailored to each
                      client’s needs. Whether it’s residential, commercial, or
                      corporate interiors, our design philosophy blends
                      creativity with practicality, ensuring that every space is
                      not only visually stunning but also comfortable and
                      efficient. Our interior designs focus on maximizing space
                      utilization, enhancing comfort, and reflecting your
                      personal style through innovative design solutions.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <p className="service-details-points">
                        Personalized interior design for residential, commercial, and corporate spaces.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Custom furniture, lighting, and decor selections to suit your aesthetic.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Space planning to optimize functionality and flow.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Use of high-quality materials and modern design techniques.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Integration of sustainable, eco-friendly interior solutions.
                        </p>
                      </li>
                    </ul>
                    {/* <div className="text-left">
                      <p  className="site-button btn-half">
                        <span>Read More</span>
                      </p>
                    </div> */}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content">
                          {/* <NavLink to={"#"} className="play-now" data-toggle="modal" data-target="#myModal">
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </NavLink>

                                                    <div className="video-section-bottom">
                                                        <h3 className="sx-title text-white">25 Years<br />Experience</h3>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <ReactPlayer url="https://vimeo.com/34741214" />
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

export default InteriorDesignAbout3;
