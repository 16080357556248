import React from 'react';
import CountUp from 'react-countup';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const testimonials = [
    {
        image: require('./../../images/testimonials/google-icon.png'),
        reviewername: 'Basavaraj patil',
        position: 'Client',
        review: '“Excellent team of architects.I had contacted them for my house renovation.They listened patiently ,listed all the requirements and gave the best design which was liked by my all family members.They came all the way to Hubli to Verify and check the progress of the work.”'
    },
    {
        image: require('./../../images/testimonials/google-icon.png'),
        reviewername: 'Niranjan Pandit',
        position: 'Client',
        review: '“Studio sarva is filled with steadfast associates, who are willing to give everything they have for the satisfaction of the ones who come searching for assistance. Overall it is filled with the most dedicated people you will ever meet”'
    },
    {
        image: require('./../../images/testimonials/google-icon.png'),
        reviewername: 'Sandeep Ranganath',
        position: 'Client',
        review: '“Contacted for living and bedroom area interior design, Trust me they have good design team and execution team. Loved it Thank you Team SARVA”'
    },
    {
        image: require('./../../images/testimonials/google-icon.png'),
        reviewername: 'Prajwal Nagaraju',
        position: 'Client',
        review: '“Trusted and best architects to have the designs with updated and unique materials and designs”'
    },
    // {
    //     image: require('./../../images/testimonials/pic3.jpg'),
    //     reviewername: 'Rosalina D. William',
    //     position: 'Client',
    //     review: '“Trusted and best architects to have the designs with updated and unique materials and designs”'
    // },
]

var bgimg1 = require('./../../images/background/bg1.png');
var bgimg2 = require('./../../images/background/bg-5.png');
var bgimg3 = require('./../../images/background/cross-line2.png');
var bgimg4 = require('./../../images/background/bg-12.jpg');

class Testimonials1 extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: true,
            margin: 30,
            autoplayTimeout: 6000,
            nav: false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                991: {
                    items: 1
                }
            }
        };
        return (
            <>
                <div className="section-full container-fluid">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 bg-gray bg-cover bg-no-repeat" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                <div className="sx-left-part mobile-page-padding">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="sx-separator-outer separator-left">
                                            <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg3 + ')' }}>
                                                <h3 className="sep-line-one">Infographic</h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="counter-blocks">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <div className="sx-count  text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                                    <h2 className="st-count-number sx-text-primary text-left"><span className="counter"><CountUp end={6} duration={5} /></span>+</h2>
                                                    <h4 className="m-tb0">Years of Experience</h4>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <div className="sx-count text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                                    <h2 className="st-count-number sx-text-primary text-left"><span className="counter"><CountUp end={350} duration={5} /></span>+</h2>
                                                    <h4 className="m-tb0">Project Taken</h4>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <div className="sx-count text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                                    <h2 className="st-count-number sx-text-primary text-left"><span className="counter"><CountUp end={95} duration={5} /></span>%</h2>
                                                    <h4 className="m-tb0">Happy Clients</h4>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <div className="sx-count text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                                    <h2 className="st-count-number sx-text-primary text-left"><span className="counter"><CountUp end={20} duration={5} /></span><span>+</span></h2>
                                                    <h4 className="m-tb0">Projects in hand</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 bg-white bg-repeat" style={{ backgroundImage: 'url(' + bgimg4 + ')' }}>
                                <div className="sx-right-part mobile-page-padding  p-t80 p-b50">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="sx-separator-outer separator-left">
                                            <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg3 + ')' }}>
                                                <h3 className="sep-line-one">Testimonial</h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    {/* TESTIMONIAL START */}
                                    <OwlCarousel className="owl-carousel testimonial-home number-slider" {...options}>
                                        {testimonials.map((item, index) => (
                                            <div className="item" key={index}>
                                                <div className="testimonial-2  hover-animation-1">
                                                    <div className="testimonial-detail clearfix">
                                                        <div className="testimonial-pic  scale-in-center"><img src={item.image} alt="" width={100} height={100} /></div>
                                                        <h4 className="testimonial-name">{item.reviewername}</h4>
                                                        <span className="testimonial-position">{item.position}</span>
                                                        <span className="fa fa-quote-right" />
                                                    </div>
                                                    <div className="testimonial-text bg-white  shadow-sm">
                                                        <p>{item.review}</p>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}

                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Testimonials1;