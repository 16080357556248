import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/urban-about.jpg");

class UrbanDesignAbout3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <h3 className="m-t0 m-b20 sx-tilte">
                      Urban Design and Development
                    </h3>
                    <p>
                      At Studio Sarva Architecture, we specialize in providing
                      comprehensive urban design and development services that
                      focus on creating sustainable, functional, and
                      aesthetically pleasing urban spaces. Our approach to urban
                      design integrates environmental sustainability, community
                      needs, and innovative architectural solutions to enhance
                      the quality of life in cities and towns. From master
                      planning to infrastructure development, we aim to build
                      spaces that are adaptable, inclusive, and designed for the
                      future.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <p className="service-details-points">
                        Master planning for urban spaces and developments.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Sustainable urban infrastructure and landscape design.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Integration of public spaces, transport systems, and green spaces.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Strategic land use planning and zoning guidance.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Focus on community engagement and social inclusion.
                        </p>
                      </li>
                    </ul>
                    {/* <div className="text-left">
                      <p  className="site-button btn-half">
                        <span>Read More</span>
                      </p>
                    </div> */}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <ReactPlayer url="https://vimeo.com/34741214" />
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

export default UrbanDesignAbout3;
