import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/services/res.png");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <h3 className="m-t0 m-b20 sx-tilte">
                      Residential Construction
                    </h3>
                    <p>
                      At Studio Sarva Architecture, we transform your vision of
                      a perfect home into reality. Our expert team specializes
                      in crafting beautiful, sustainable, and functional
                      residential spaces that reflect your lifestyle. We manage
                      every aspect of the construction process, ensuring a
                      smooth and stress-free experience from design to delivery.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <p className="service-details-points">
                        Personalized home designs tailored to your needs.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Sustainable materials and energy-efficient solutions.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        End-to-end project management with transparent communication.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Meticulous attention to detail in every aspect.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Experienced team delivering on time and within budget.
                        </p>
                      </li>
                    </ul>
                    {/* <div className="text-left">
                      <p  className="site-button btn-half">
                        <span>Read More</span>
                      </p>
                    </div> */}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content">
                          {/* <NavLink to={"#"} className="play-now" data-toggle="modal" data-target="#myModal">
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </NavLink>

                                                    <div className="video-section-bottom">
                                                        <h3 className="sx-title text-white">25 Years<br />Experience</h3>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <ReactPlayer url="https://vimeo.com/34741214" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
