import React from "react";
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import InteriorDesignSlider from "../Elements/InteriorDesignSlider";
import InteriorDesignAbout3 from "../Elements/InteriorDesignAbout3";
import InteriorDesignAbout5 from "../Elements/InteriorDesignAbout5";
import InteriorDesignService7 from "../Elements/InteriorDesignService7";
import InteriorDesignStatistics from "../Elements/InteriorDesignStatistics";
import InteriorDesignPlans from "../Elements/InteriorDesignPlans";
import Banner from "../Elements/Banner";
import Whatsapp from "../Common/Whatsapp";

var bnrimg = require("./../../images/interior-2.jpg");
var bgimg2 = require("./../../images/background/cross-line2.png");

class InteriorDesign extends React.Component {
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          <Banner title="Interior Design" pagename="Interior Design" description="We create personalized, stylish, and functional interior spaces that reflect your lifestyle or business, combining aesthetics with practicality for a truly inspiring environment..." bgimage={bnrimg}/>
          {/* SECTION CONTENT START */}
          {/* <InteriorDesignSlider /> */}
          <InteriorDesignAbout3 bgcolor="bg-gray" />

          <div className="main-div">
            <div className="container">
              <div className="section-head">
                <div className="sx-separator-outer separator-center">
                  <div
                    className="sx-separator bg-white bg-moving bg-repeat-x"
                    style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                  >
                    <h3 className="sep-line-one">
                    Why Choose Studio Sarva Architects for Interior Design?
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-door" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Tailored Design Solutions</h4>
                      <p>
                      We create interior spaces that are customized to your unique preferences, ensuring that every design element—from color palettes to furniture—reflects your personal style and meets your functional needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">
                      Attention to Detail
                      </h4>
                      <p>
                      Our interior design services focus on precision and fine detailing, ensuring that every aspect of the space, from lighting to furniture placement, is thoughtfully considered and executed to perfection.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Innovative Design Concepts</h4>
                      <p>
                      We integrate the latest design trends and technologies to create modern, sophisticated interiors. Whether you prefer a minimalist look or a luxurious feel, we craft spaces that inspire and delight.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Functional and Aesthetic Balance</h4>
                      <p>
                      Our designs strike the perfect balance between functionality and aesthetics, creating interiors that are both beautiful and practical for everyday living or business use.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <InteriorDesignAbout5 />
          <InteriorDesignService7 />
          <InteriorDesignStatistics />
          <InteriorDesignPlans />
          {/* SECTION CONTENT END  */}
        </div>
        <Whatsapp />
        <Footer />
      </>
    );
  }
}

export default InteriorDesign;
