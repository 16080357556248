import React from "react";
import { NavLink } from "react-router-dom";
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import ReactPlayer from "react-player";
import Slider6 from "../Elements/Slider6";
import Services7 from "../Elements/Services7";
import Statistics1 from "../Elements/Statistics1";
import Plans1 from "../Elements/Plans1";
import About3 from "../Elements/About3";
import About5 from "../Elements/About5";
import Whatsapp from "../Common/Whatsapp";

var bnrimg = require("./../../images/banner/4.jpg");
var bgimg2 = require('./../../images/background/cross-line2.png');

class ServiceDetail extends React.Component {
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          {/* <Banner title="Service Details" pagename="Service Detail" description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized." bgimage={bnrimg}/> */}
          {/* SECTION CONTENT START */}
          <Slider6 />
          <About3 bgcolor="bg-gray" />
          
          
          <div className="main-div">
            <div className="container">
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">Why Choose Studio Sarva Architects for Residential Construction?</h3>
                </div>
              </div>
            </div>
              <div className="row">
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-door" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Tailored Design Solutions</h4>
                      <p>
                      We create custom home designs that reflect your unique style, preferences, and lifestyle, ensuring every detail aligns with your vision.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Sustainable and Eco-Friendly Practices</h4>
                      <p>
                      Our approach integrates sustainable materials and energy-efficient designs, promoting environmentally responsible and future-ready homes.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Comprehensive Project Management</h4>
                      <p>
                      From planning to execution, we handle every stage of the construction process, ensuring seamless communication and timely delivery.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b30">
                  <div className="sx-icon-box-wraper p-a30 center bg-white shadow">
                    <div className="sx-icon-box-lg inline-icon text-primary m-b20 radius bg-secondry  scale-in-center">
                      <span className="icon-cell sx-text-primary">
                        <i className="flaticon-ruler-1" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h4 className="sx-tilte">Expert Craftsmanship</h4>
                      <p>
                      Our experienced team brings exceptional skill, precision, and innovation to every project, ensuring the highest quality standards in modern construction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <About5 />
          <Services7 />
          <Statistics1 />
          <Plans1 />
          {/* SECTION CONTENT END  */}
        </div>
        <Whatsapp />
        <Footer />
      </>
    );
  }
}

export default ServiceDetail;
