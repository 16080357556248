import React from "react";
import { NavLink } from "react-router-dom";

const services = [
  {
    count: "01",
    title: "Tailored Solutions",
    flaticon: "flaticon-sketch",
    image: require("./../../images/services/service-projects/1.png"),
    description:
      "We recognize that each business has unique demands. Our approach involves collaborating closely with you to create customized designs that reflect your operational needs and brand identity.",
  },
  {
    count: "02",
    title: "Time and Cost Efficiency",
    flaticon: "flaticon-stairs",
    image: require("./../../images/services/service-projects/2.png"),
    description:
      "We ensure that your project is delivered on schedule and within budget, employing efficient processes that minimize delays and unexpected expenses without compromising quality.",
  },
  {
    count: "03",
    title: "Transparent Process",
    flaticon: "flaticon-window",
    image: require("./../../images/services/service-projects/3.png"),
    description:
      "From the initial design phase to the final handover, we maintain clear and consistent communication, keeping you informed and involved at every stage, ensuring your needs and expectations are fully met.",
  },
];

var bgimg1 = require("./../../images/background/cross-line2.png");

class CommercialService7 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b50 bg-white">
          <div className="container">
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                >
                  <h3 className="sep-line-one">
                  Core Reasons to Choose Studio Sarva Architects
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              {services.map((item, index) => (
                <div className="col-lg-4 col-md-12 m-b30" key={index}>
                  <div
                    className="bgcall-block d-flex flex-wrap justify-content-center align-content-end bg-cover overlay-wraper"
                    style={{ backgroundImage: "url(" + item.image + ")" }}
                  >
                    <div className="overlay-main bg-black opacity-05" />
                    <div className="bg-content-mid-outer">
                      <div className="bg-content-mid">
                        <div className="sx-icon-box-wraper center   text-white">
                          <div className="icon-lg m-b15">
                            <span className="icon-cell">
                              <i className={item.flaticon} />
                            </span>
                          </div>
                          <div className="icon-content m-b30">
                            <h4 className="sx-tilte">
                              {" "}
                              <NavLink
                                to={"/services-detail"}
                                className="sx-text-white"
                              >
                                {item.title}
                              </NavLink>
                            </h4>
                            <p>{item.description}</p>
                          </div>
                        </div>
                        <span className="bgcall-block-number">
                          {item.count}
                        </span>
                        <div className="bg-overlay" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CommercialService7;
