import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../images/background/bg-4.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class TrunkeyPlans extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full text-center bg-gray bg-no-repeat p-t80 p-b80 bg-top-right mobile-page-padding"
          tyle={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">
                    Studio Sarva Architects-Top Turnkey Construction company in
                    Bangalore
                  </h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="pricingtable-row p-t20">
                <p className="service-last-section">
                  Studio Sarva Architects is recognized as one of the top
                  turnkey construction companies in Bangalore, offering
                  comprehensive solutions that simplify the building process for
                  our clients. We specialize in managing every aspect of your
                  project, from initial design to final handover, ensuring a
                  seamless and efficient experience. Our commitment to
                  high-quality craftsmanship, innovative design, and sustainable
                  practices sets us apart in the industry. With a focus on
                  client satisfaction and clear communication, we transform your
                  vision into reality, delivering beautifully finished spaces
                  that meet the highest standards of functionality and
                  aesthetics. Choose Studio Sarva for a stress-free construction
                  journey and exceptional results in your next project.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TrunkeyPlans;
