import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../images/background/bg-4.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class CommercialPlans extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full text-center bg-gray bg-no-repeat p-t80 p-b80 bg-top-right mobile-page-padding"
          tyle={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">
                    Studio Sarva Architects - Top Commercial Construction
                    Company in Bangalore
                  </h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="pricingtable-row p-t20">
                <p className="service-last-section">
                  Studio Sarva Architects is recognized as a leading commercial
                  construction company in Bangalore, dedicated to simplifying
                  the building process for our clients. We manage all phases of
                  your project with precision and care, ensuring a seamless
                  experience from start to finish. Our commitment to
                  high-quality craftsmanship, innovative design, and sustainable
                  practices distinguishes us in the industry. With a focus on
                  client satisfaction and a collaborative approach, we deliver
                  beautifully crafted commercial spaces that meet the highest
                  standards of functionality and aesthetic appeal. Choose Studio
                  Sarva for your next commercial project, and experience a
                  partnership dedicated to excellence and exceptional results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CommercialPlans;
