import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../images/background/bg-4.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class HospitalityPlans extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full text-center bg-gray bg-no-repeat p-t80 p-b80 bg-top-right mobile-page-padding"
          tyle={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">
                    Studio Sarva Architects - Premier Hospitality Construction
                    Company in Bangalore
                  </h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="pricingtable-row p-t20">
                <p className="service-last-section">
                  Studio Sarva Architects is recognized as a premier hospitality
                  construction company in Bangalore, dedicated to creating
                  exceptional spaces that elevate the guest experience. We
                  specialize in managing all aspects of your project, ensuring a
                  seamless and efficient process from initial design to final
                  handover. Our commitment to quality craftsmanship, innovative
                  design, and sustainable practices sets us apart in the
                  hospitality industry. With a focus on client satisfaction and
                  a collaborative approach, we transform your vision into
                  beautifully crafted spaces that meet the highest standards of
                  functionality and aesthetics. Choose Studio Sarva for your
                  next hospitality project, and experience a dedicated
                  partnership that delivers outstanding results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HospitalityPlans;
