import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../images/background/bg-4.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class ConceptDesignPlans extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full text-center bg-gray bg-no-repeat p-t80 p-b80 bg-top-right mobile-page-padding"
          tyle={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">
                    Studio Sarva Architects - Leading Concept Design Company in
                    Bangalore
                  </h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="pricingtable-row p-t20">
                <p className="service-last-section">
                  As one of Bangalore’s premier concept design firms, Studio
                  Sarva Architects is committed to creating visionary designs
                  that set the stage for successful projects. Our unique
                  approach blends creativity, innovation, and sustainability,
                  providing you with concepts that are both practical and
                  aesthetically exceptional. Whether you're in the commercial,
                  residential, or hospitality sector, our team brings your ideas
                  to life with unparalleled expertise and a commitment to
                  excellence. Choose Studio Sarva for concept designs that are
                  bold, inspiring, and grounded in real-world feasibility.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ConceptDesignPlans;
