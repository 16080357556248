import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/urban-2.jpg");
var bgimg2 = require("./../../images/background/cross-line2.png");

class UrbanDesignAbout5 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">
                    Excellence in Urban Design and Development
                  </h3>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <p>
                      At Studio Sarva Architects, our urban design and
                      development services are built on the principles of
                      innovation, sustainability, and functionality. We deliver
                      well-planned urban spaces that enhance quality of life,
                      create social cohesion, and are adaptable to future
                      growth. From strategic master planning to the development
                      of mixed-use spaces, our goal is to shape urban
                      environments that are both sustainable and vibrant.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <p className="service-details-points">
                        Comprehensive master planning for urban and suburban developments.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Integration of green infrastructure and eco-friendly building practices.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Creation of walkable, accessible, and community-focused spaces.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Innovative designs that respond to future urban challenges.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Collaboration with local authorities and community stakeholders.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UrbanDesignAbout5;
