import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/services/commercial2.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class CommercialAbout5 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">
                    Excellence in Commercial Construction
                  </h3>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <p>
                      At Studio Sarva Architects, we provide exceptional
                      commercial construction solutions tailored to meet the
                      diverse needs of businesses. Our comprehensive services
                      cover every aspect of the construction process, from
                      initial design to final handover, ensuring that your
                      project is completed efficiently and to the highest
                      standards. Our dedicated team combines industry expertise
                      with innovative techniques to create functional,
                      attractive spaces that enhance productivity and align with
                      your brand identity. With a focus on quality, efficiency,
                      and client satisfaction, we turn your commercial vision
                      into reality, delivering projects that reflect excellence
                      in every detail.
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <p className="service-details-points">
                        Comprehensive project management for commercial builds.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Use of premium materials and the latest construction methods.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Timely execution with a commitment to staying on budget.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Custom designs that cater to your business requirements.
                        </p>
                      </li>
                      <li>
                        <p className="service-details-points">
                        Open communication and collaboration throughout the project.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CommercialAbout5;
