import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: '01',
        title: 'Architectural Consultancy',
        link: '/architectural-consultancy',
        flaticon: 'flaticon-sketch',
        description: 'Our architectural consultancy offers expert guidance from concept development to construction, ensuring innovative, sustainable, and aesthetically pleasing designs that meet your vision...',
    },
    {
        count: '02',
        title: 'Turnkey Solutions',
        link: '/turnkey-projects',
        flaticon: 'flaticon-house',
        description: 'Our turnkey solutions provide complete project management, handling every phase from design to construction, ensuring a smooth, stress-free experience and a fully finished property...',
    },
    {
        count: '03',
        title: 'Interior Design',
        link: '/interior-design',
        flaticon: 'flaticon-door',
        description: 'We create personalized, stylish, and functional interior spaces that reflect your lifestyle or business, combining aesthetics with practicality for a truly inspiring environment...',
    },
    {
        count: '04',
        title: 'Urban Design & Development',
        link: '/urban-design',
        flaticon: 'flaticon-skyline',
        description: 'Our urban design and development services focus on sustainable and community-centric spaces, transforming cities with innovative designs that prioritize functionality and aesthetic appeal...',
    }
    
    
]

var bgimg1 = require('./../../images/background/bg-5.png');
var bgimg2 = require('./../../images/background/cross-line2.png');

class Services6 extends React.Component {
    render() {

        return (
            <>
                <div className="section-full  mobile-page-padding bg-gray  p-t80 p-b50 bg-repeat overflow-hide" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                    <div className="container">
                        <div className="large-title-block text-center">
                            {/* TITLE START */}
                            {/* <div className="section-head">
                                <div className="sx-separator-outer separator-left">
                                    <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                        <h3 className="sep-line-one">Services</h3>
                                    </div>
                                </div>
                            </div> */}
                            {/* TITLE END */}
                        </div>
                        <div className="section-content">
                            <div className="row number-block-two-outer">
                                {services.map((item, index) => (
                                    <div className="col-lg-6 col-md-6 col-sm-12 m-b30" key={index}>
                                        <div className="number-block-two animate-in-to-top bdr-gray-light bdr-solid bdr-1">
                                            <div className="figcaption bg-white  p-a30">
                                                <h4 className="m-t0">{item.title}</h4>
                                                <p>{item.description}</p>
                                                <NavLink to={item.link} className="site-button-link">Read More</NavLink>
                                                <div className="figcaption-number animate-in-to-top-content">
                                                    <span>{item.count}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Services6;